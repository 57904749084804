import I18n from './I18n'

function hidePopovers(e) {
  $('[data-toggle="popover"]').each(function () {
    //the 'is' for buttons that trigger popups
    //the 'has' for icons within a button that triggers a popup
    if (
      !$(this).is(e.target) &&
      $(this).has(e.target).length === 0 &&
      $('.popover').has(e.target).length === 0
    ) {
      $(this).popover('hide')
    }
  })
}

function cleanPopoverState(e) {
  $(e.target).data('bs.popover').inState = {
    click: false,
    hover: false,
    focus: false,
  }
}
export function registerClickAwayHidePopover(): void {
  // Popover on Vention
  $('body').on('click', hidePopovers)
  $('body').on('hidden.bs.popover', cleanPopoverState)
}

export function unregisterClickAwayHidePopover(): void {
  // Popover on Vention
  $('body').unbind('click', hidePopovers)
  $('body').unbind('hidden.bs.popover', cleanPopoverState)
}

export function scrollToClass(pClassName: string): void {
  $('html, body').animate({ scrollTop: $('.' + pClassName).position().top - 100 }, 'fast')
}

export function downloadDesignStepFile(assemblyGraphJSON, downloadBtnId, callback) {
  let pDesignNumber
  let pDesignVersion
  let pGraphJson

  function forceDownload(_pStepDownloadLink) {
    // Force download of .STEP file
    location.replace(_pStepDownloadLink.assembly_step_uri)

    // Set href to new download link
    $('#' + downloadBtnId).attr('href', _pStepDownloadLink.assembly_step_uri)
    $('#' + downloadBtnId).attr('onclick', null)
  }
  function showErrorMessage(_pErrorMessage) {
    
    toastr.error(_pErrorMessage)
  }
  function preferredHost() {
    if (location.protocol.match(/http(s?)/)) {
      return location.protocol + '//' + location.hostname + ':' + location.port
    } else {
      // Would be nice for debugging in standalone, but appears not to work well due to CORS enforcements.
      return 'http://localhost:3000'
    }
  }
  function doAjax(pOptions) {
    $.ajax(pOptions)
  }
  function doExport(pUri) {
    doAjax({
      url: pUri,
      type: 'POST',
      data: {
        payload: {
          design_number: pDesignNumber,
          design_version: pDesignVersion,
          graph_json: pGraphJson,
        },
      },
      dataType: 'json',
      success: function (_pData) {
        forceDownload(_pData)
        callback()
      },
      error: function () {
        showErrorMessage('Sorry, there was an unexpected error.')
        callback()
      },
    })
  }

  if (undefined != assemblyGraphJSON && null != assemblyGraphJSON) {
    const pAssemblyGraph = assemblyGraphJSON
    pDesignNumber = pAssemblyGraph.designId
    pDesignVersion = pAssemblyGraph.graphVersion
    pGraphJson = JSON.stringify(pAssemblyGraph.graph)
    doAjax({
      url: preferredHost() + '/alloc_exporter_endpoint',
      type: 'GET',
      dataType: 'json',
      data: {
        id: pDesignNumber,
        type: 'Design',
      },
      success: function (_pData) {
        if (undefined != _pData) {
          if (_pData.quota_reached) {
            showErrorMessage(I18n.t('frontend.components.common.step_file_download.daily_limit_hit'))
          } else {
            doExport(_pData.endpoint + '/convert_to_step')
          }
        } else {
          console.log('alloc_exporter_endpoint did not return endpoint')
        }
      },
      error: function () {
        console.log('Failed to alloc_exporter_endpoint!')
      },
    })
  } else {
    showErrorMessage('Error: Assembly graph is undefined. Please contact support.')
  }
}
